import React from 'react'
import Layout from '../../components/layout/Layout'
import Breadcrumb from '../../components/layout/Breadcrumb'
import MdxDocList from '../../components/thoughts/MdxDocList'

const Blog = () => {
  const breadcrumbList = [
    { name: 'Thoughts', url: '/thoughts' },
    { name: 'Blog' },
  ]

  return (
    <Layout>
      <div>
        <Breadcrumb crumbList={breadcrumbList} showCloseButton={true} />
        <MdxDocList />
      </div>
    </Layout>
  )
}
export default Blog
