import React from 'react'
import { Link } from 'gatsby'
import { navigate } from '@gatsbyjs/reach-router'

const Breadcrumb = ({ crumbList, showCloseButton }) => {
  let content = []

  if (crumbList && crumbList.length > 0) {
    crumbList.forEach((item, index) => {
      if (item.name) {
        if (item.url) {
          content.push(<Link to={item.url}>{item.name}</Link>)
        } else {
          content.push(<span className="nonlink">{item.name}</span>)
        }
        if (index + 1 < crumbList.length) {
          content.push(<span className="spacer"> > </span>)
        }
      }
    })
  }

  if (showCloseButton) {
    content.push(
      <button
        className="right xsmall photos__closeButton"
        onClick={() => navigate(-1)}
        aria-label="close"
      />
    )
  }

  if (content.length > 0) {
    return (
      <div id="crumbwrapper">
        <div id="breadcrumb">{content}</div>
        <div id="spacer"> foo</div>
      </div>
    )
  }
  return ''
}

export default Breadcrumb
